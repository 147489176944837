<template>
  <nav class="nav-bar">
    <router-link
      class="nav-bar__link"
      :class="{ active: currentLocation === roots.main }"
      to="/"
    >
      {{ 'Магазин' }}
    </router-link>
    <router-link
      class="nav-bar__link"
      :class="{ active: currentLocation === roots.faq || currentLocation === roots.faqAlternative }"
      to="/faq"
    >
      {{ 'FAQ' }}
    </router-link>
    <router-link
      class="nav-bar__link"
      :class="{ active: currentLocation === roots.contacts || currentLocation === roots.contactsAlternative }"
      to="/contacts"
    >
      {{ 'Контакти' }}
    </router-link>
  </nav>
</template>

<script>
import { computed } from 'vue';
import router from '@/router';

export default {
  setup () {
    const currentLocation = computed(() => router.currentRoute.value.fullPath);

    const roots = {
      main: '/',
      howToWork: '/about-us',
      howToWorkAlternative: '/about-us/',
      faq: '/faq',
      faqAlternative: '/faq/',
      contacts: '/contacts',
      contactsAlternative: '/contacts/',
    };

    return { roots, currentLocation };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";
@import "../assets/mixins";

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to($tablet) {
    flex-direction: column;
    align-items: start;
    padding-top: 1rem;
  }

  &__link {
    color: $col-text;
    font-size: 1.6rem;
    font-family: 'GetVoIP Grotesque', sans-serif;
    padding: 0 1rem;
    margin: 0 1rem;

    @include respond-to($tablet) {
      font-size: 1.8rem;
      margin: 0;
      padding: 0.5rem 0;
    }

    &.active {
      color: #858580;
      border-radius: 0.4rem;
      border-bottom: $nav-btn-active-border;

      @include respond-to($tablet) {
        border: none;
      }
    }
  }
}
</style>
