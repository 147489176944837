<script setup>
import logoDesk from '../../public/logo.svg';
import logoMob from '../../public/favicon.svg';
import burger from '../../public/burger.svg';
import BurgerMenu from '@/components/BurgerMenu.vue';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';

const route = useRoute();

const isMain = computed(() => route.fullPath.length > 1)

const isShowMenu = ref(false);
</script>

<template>
  <header class="header" :class="{'header-bg': isMain}">
    <div class="container">
      <div class="header__wrapper">
        <router-link to="/" class="header__logo">
          <img
              :src="logoMob"
              :srcset="`
              ${logoMob} 576w,
              ${logoDesk}
            `"
              alt="Logo"
          >
        </router-link>

        <nav class="header__nav">
          <button @click="isShowMenu = !isShowMenu" class="header__burger">
            <img :src="burger" alt="Menu icon">
          </button>

          <ul class="header__nav-list">
            <li class="header__nav-list-item">
              <router-link to="/contacts" class="header__nav-link">
                {{ 'Контакти' }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <BurgerMenu
          v-if="isShowMenu"
          @close-menu="isShowMenu = false"
      />
    </div>
  </header>
</template>

<style scoped lang="scss">
@import "../assets/_mixins";

.header-bg {
  background-color: #2A2B28;

  @media (max-width: 575px) {
    background-color: #ffffff;
  }
}
.header {
  --link-inline-padding: 24px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  height: $headerHeight;
  padding: 18px 0;

  & .container {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__logo {
    display: block;
    width: 100px;
    max-width: 120px;

    & > img {
      display: block;
      width: 100%;
    }
  }

  &__nav {
    transform: translateX(var(--link-inline-padding));
  }

  &__burger {
    display: none;
  }

  &__nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__nav-link {
    display: block;
    padding: 12px var(--link-inline-padding);
    line-height: 140%;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #fff;
    white-space: nowrap;
    transition: opacity 0.3s linear;

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }

  @media (max-width: 575px) {
    &__wrapper {
      flex-direction: row-reverse;
    }

    &__logo {
      max-width: 40px;
    }

    &__nav {
      transform: initial;
    }

    &__burger {
      display: block;
      max-width: 40px;

      & > img {
        display: block;
        width: 100%;
      }
    }

    &__nav-list {
      display: none;
    }
  }
}
</style>